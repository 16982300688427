
.tox-notifications-container {
  display: none;
}

.MuiPopover-paper.MuiPaper-rounded {
  border: 1px solid #ADB8CC;
  border-radius: 12px !important;
}

li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button:hover {
  font-weight: 700;
}